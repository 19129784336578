import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
    {	path: "", redirectTo: 'edi', pathMatch: 'full'},
    // { path: 'landing',  loadChildren: () => import('./login/login.module').then(m => m.LoginModule)},
    { path: 'edi',  loadChildren: () => import('./edi/edi.module').then(m => m.EdiModule),data:['edi'] },
    { path: 'bexio',  loadChildren: () => import('./bexio/bexio.module').then(m => m.BexioModule),data:['bexio']},
   // { path: 'categories/:category',  loadChildren: () => import('./categories/categories.module').then(m => m.CategoriesModule)},
  //  { path: 'sections/:section', loadChildren: () => import('./sections/sections.module').then(m => m.SectionsModule) },
    //{ path: 'articles/:article',  loadChildren: () => import('./articles/articles.module').then(m => m.ArticlesModule) },
    { path: 'search/:searchkey',  loadChildren: () => import('./search/search.module').then(m => m.SearchModule) },
    { path: 'ticket',  loadChildren: () => import('./ticket/ticket.module').then(m => m.TicketModule) },
    { path: '**', redirectTo: 'edi' }
  ];

  @NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy'})],
    exports: [RouterModule]
  })
  export class AppRoutingModule { }