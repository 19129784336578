<div class="row header">
  <div class="col-xl-6 col-lg-7 col-md-6 col-sm-12">
    <div id="logo-group">
      <div *ngIf="appService.module=='bexio'"><img src='assets/img/iconSvg/bexio.png' alt='Bexio' routerLink="/bexio" style="cursor:pointer">&nbsp; <span>powered by </span>&nbsp;</div>
      <span id="logo" routerLink="/edi"></span>
  </div>
  </div>

  <div class="col-xl-6 col-lg-5 col-md-6 col-sm-12 d-flex justify-content-end  pr-0 header_right_content">
    <!-- <div class="header-link">
      <div class="text-white">
        <div class="billing-cycle model-7 d-flex">
          <span class="theme-header-text-color">Dark</span>
          <div class="mx-3 checkbox mb-2">
            <input type="checkbox">
            <label></label>
          </div>
          <span class="theme-header-text-color">White</span>
        </div>
      </div>
    </div> -->
    <!-- <div class="header-link headLang">
      <sa-language-selector></sa-language-selector>
    </div> -->
    <!-- <div class="header-link" [ngClass]="fullScreenSelected?'':'menuSelected'"><sa-full-screen (onFullScreenSelect)="selectFullScreen($event)"></sa-full-screen></div> -->
    <!-- <div class="header-link" [ngClass]="activitySelected?'menuSelected':''">
      <sa-activities (onActivitySelect)="selectActivity($event)"  [activityType]="'message'"></sa-activities>
    </div>
    <div class="header-link"  [ngClass]="notificationSelected?'menuSelected':''">
        <sa-activities (onActivitySelect)="selectActivity($event)" [activityType]="'notification'"></sa-activities>                
    </div> -->
    <!-- <div class="header-link" title="My tickets" (click)="redirectTologin()"><i class="fas fa-md fa-clipboard-list iconClass"></i></div>
    <div class="header-link webcomponent-option" [hidden]="!isLoggedIn">
      <span style="padding: 6px 20px;" class="webComponetsBorder webComponetsHoverColor">
            <div id="webComponetsOptions"></div>
        </span>
        
    </div> 
     <div class="header-link webcomponent-profile" [hidden]="!isLoggedIn">
      <span style="padding: 2px 20px 6px;display: flex;align-items: center" class="webComponetsHoverColor">
              <div id="webComponetsProfile"></div>
        </span>
    </div> -->
    
  </div>
</div>
<div class="clearfix"></div> 
<div class="border-green"></div>
<div class="content"> 
  <div class="search-div">
    <div class="help-text">{{'common_help_text' | i18n}}</div>
    <div class="search-input">
      <input type="text" class="search" placeholder="{{'search_placeholder' | i18n}}" [(ngModel)]="searchKey" id="search">
      <i class="fa fa-search" (click)="goToSearch()"></i>
      <i class="fa fa-times" (click)="searchKey='';goToSearch()" *ngIf="searchKey!=''"></i>
    </div>
  </div>
  <div class="row" *ngIf="this.router.url.indexOf('landing')==-1 && this.router.url.indexOf('search')==-1" style="text-align: center;margin: 40px 0px 10px 0px;">
      <div class="width-100">
          <span *ngFor="let breadcrumb of this.appService.breadcrumpsArray;let i= index">
              <a  style="color: #101010; font-size: 12px; cursor: pointer;" (click)="goTo(breadcrumb.url)">{{breadcrumb.name}}</a>
              <span *ngIf="i<this.appService.breadcrumpsArray.length-1"> / </span>
          </span>
      </div>
      
  </div>
  <router-outlet></router-outlet>
  
  <footer class="iomarket-section footer-section">
      <!-- <div class="subscribe-box pb-xl-5 pb-md-4 pb-3">
          <div class="container">
              <div class="row align-items-center justify-content-between">
                  <div class="col-lg-5 mb-lg-0 mb-3">
                      <div class="newsletter-text">
                                                  <div class="section-second-title mb-3 pl-3 position-relative">
                              <div class="newsletter">{{'common_news_letter' | i18n}}</div>
                              <div class="join-now">{{'common_join_now' | i18n}}                           </div>
                          </div>
                          <p class="mail-text">{{'common_mail_text' | i18n}}</p>
                                              </div>
                  </div>
                  <div class="col-lg-6">
                      <div class="subscribe-form position-relative">
                                                  
    <section id="yikes-mailchimp-container-2" class="yikes-mailchimp-container yikes-mailchimp-container-2 ">
          <form id="iom-english-2" class="yikes-easy-mc-form yikes-easy-mc-form-2 yikes-mailchimp-form-inline  " method="POST" data-attr-form-id="2">
  
                            <label for="yikes-easy-mc-form-2-EMAIL" class="label-inline EMAIL-label yikes-mailchimp-field-required ">
                      <input id="yikes-easy-mc-form-2-EMAIL" name="EMAIL" placeholder="{{'common_enter_mail' | i18n}}" class="yikes-easy-mc-email field-no-label" required="required" type="email" value="">
                    </label>
          <input type="hidden" name="yikes-mailchimp-honeypot" id="yikes-mailchimp-honeypot-2" value="">
          <input type="hidden" name="yikes-mailchimp-associated-list-id" id="yikes-mailchimp-associated-list-id-2" value="be99d202ad">
          <input type="hidden" name="yikes-mailchimp-submitted-form" id="yikes-mailchimp-submitted-form-2" value="2">
          <label class="empty-form-inline-label submit-button-inline-label"><span class="empty-label labels-hidden">&nbsp;</span><button type="submit" class="yikes-easy-mc-submit-button yikes-easy-mc-submit-button-2 btn btn-primary btn submit-button fs-6 white-color rounded-pill  border-0"> <span class="yikes-mailchimp-submit-button-span-text">{{'common_submit' | i18n}}</span></button></label>
          <input type="hidden" id="yikes_easy_mc_new_subscriber_2" name="yikes_easy_mc_new_subscriber" value="bbb53e47c1">
          <input type="hidden" name="_wp_http_referer" value="/en/">				
        </form>
      
  
        </section>
                                                </div>
                  </div>
              </div>
          </div>
      </div> -->
      <div class="footer-link">
          <div class="container">
              <div class="row align-items-center justify-content-between">
                  <div class="col-lg-9 order-lg-1 order-2 text-lg-start text-center">
                      <div class="links fs-5  font-light">
                          <ul class="d-flex flex-wrap align-items-center"><li id="menu-item-142" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-142 position-relative"><a href="https://www.io-market.com/allgemeine-geschaftsbedingungen.html" class="darkgray-color hover-green-color">{{'common_all_terms_and_conditions' | i18n}}</a></li>
  <li id="menu-item-143" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-143 position-relative"><a href="https://www.io-market.com/datenschutzerklarung.html" class="darkgray-color hover-green-color">{{'common_all_privacy_policy' | i18n}}</a></li>
  <li id="menu-item-144" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-144 position-relative"><a href="https://www.io-market.com/impressum.html" class="darkgray-color hover-green-color">{{'common_all_legal_notices' | i18n}}</a></li>
   </ul>
                      </div>
                  </div>
                  <div class="col-lg-3 text-lg-end text-center order-lg-2 order-1 mb-lg-0 mb-3">
                      <div class="social-icons">
                                                  <a href="https://www.io-market.com" class="footer-logo mb-3 d-block" title="site-logo">
                              <img src="https://www.io-market.com/images/Asset-7.png" alt="IOMARKET" class="img-social">
                          </a>
                                                  <div class="icons">
                              <a href="https://www.instagram.com/iomarketag/" title="INSTAGRAM" target="_blank" class="overflow-hidden rounded-circle d-inline-block position-relative instagram"></a>
                              <a href="https://www.linkedin.com/company/io-market-ag/" title="LINKEDIN" target="_blank" class="overflow-hidden rounded-circle d-inline-block position-relative linkedin"></a>
                              <a href="https://twitter.com/IoMarket" title="TWITTER" target="_blank" class="overflow-hidden rounded-circle d-inline-block position-relative twitter"></a>
                              <a href="https://www.facebook.com/io-market-AG-568946836854971/?modal=admin_todo_tour" title="FACEBOOK" target="_blank" class="overflow-hidden rounded-circle d-inline-block position-relative facebook"></a>
                              <a href="mailto:office@io-market.com" title="MAIL" target="_blank" class="overflow-hidden rounded-circle d-inline-block position-relative email"></a>
                          </div>
                                              </div>
                  </div>
              </div>
          </div>
      </div>
  </footer>
</div>
