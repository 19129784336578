import { Injectable } from '@angular/core';
import * as data from '../../../assets/json/activities.json';

@Injectable()
export class ActivitiesService {

  dataJson:any;

  constructor() {
  }

  getActivities()  {
    return data;
  }

}
