import { Injectable } from '@angular/core';

export type InternalStateType = {
  [key: string]: any
};

@Injectable({
  providedIn: 'root'
})
export class AppService {
  searchkey;
  module;
  _state: InternalStateType = {};
  breadcrumpsArray: any = [{"name":"io-Support","url":'/'}];
  constructor() { 
    this.setLocalStorageFunctions();
  }
  setLocalStorageFunctions() {
    Storage.prototype.getEncryptedItem = function (item) {
      if (item) {
        let encodedKey = btoa(item);
        let itemValue = localStorage.getItem(encodedKey);
        if (itemValue) {
          return atob(itemValue);
        }
      }
      return null;
    }
    Storage.prototype.setEncryptedItem = function (item, itemValue) {
      if (item && itemValue) {
        let encodedKey = btoa(item);
        let encodedValue = btoa(itemValue);
        localStorage.setItem(encodedKey, encodedValue);
        return true;
      }
      return false;
    }
    Storage.prototype.removeEncryptedItem = function (item) {
      if (item) {
        let encodedKey = btoa(item);
        localStorage.removeItem(encodedKey);
        return true;
      }
      return false;
    }
  }
}
