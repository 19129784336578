import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { I18nModule } from './i18n/i18n.module';
import { BootstrapModule } from "src/app/shared/bootstrap.module";



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    BootstrapModule,
    I18nModule
  ],
  exports: [I18nModule,BootstrapModule]
})
export class SharedModule { }
