
  // This file was autogenerated by dynamically running setEnv.ts and using dotenv for managing API key secrecy
  export const environment = {
    production: false,
    FIREBASE_API_KEY:'undefined',
    LOGIN_URL:'undefined',
    encryptionKey:'undefined',
    debug:'undefined',
    privateKey:'undefined',
    checkCookie:'undefined',
    mad:'undefined'
  };
