<ul class="header-dropdown-list ng-cloak" *ngIf="reload" (click)="showList($event)">
    <li class="dropdown" style="width: 114px;height: 40px;padding: 12px;">
        <a class="dropdown-toggle">
            <img src="assets/img/flags/{{currentLanguage.key}}.svg" class="flag" alt="{{currentLanguage.alt}}" />
            <span style="font-size: 14px;" class="theme-header-text-color language-text">&nbsp;{{ currentLanguage.title | i18n}}&nbsp;</span>

            <!-- <i class="fa fa-angle-down"></i> -->
        </a>
        <ul class="dropdown-menu language-list" style="margin-top: 11px !important;" #languageList> 
            <li [class.active]="language == currentLanguage" *ngFor="let language of languages">
                <a (click)="setLanguage(language)" class="dropdown-item pl-2 mt-1" style="font-size: 14px;"><img src="assets/img/flags/{{language.key}}.svg" class="flag" alt="{{language.alt}}" /> {{ language.title | i18n}}</a>
            </li>
        </ul>
    </li>
</ul>