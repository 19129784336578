<!-- <span id="activity" class="activity-dropdown" (click)="onToggle()">-->
  <!-- <i class="fa fa-user"></i> -->
  <!-- <img src="assets/img/iconSvg/Page-1.svg" class="svgImageIcons" alt=""> -->
  <!-- <b class="badge bg-color-red">{{count}}</b> -->
<!-- </span> -->
<div id="activity" class="activity-dropdown activity-svg" (click)="onToggle()" *ngIf="activityType=='message'">
  <b _ngcontent-emv-c19="" class="badge bg-color-blue msgNum pull-right">0</b>
</div>
<div class="notification-svg" *ngIf="activityType=='notification'" (click)="onToggle()"></div> 
<div class="ajax-dropdown activity" #dropdown [style.height]="activityType=='notification'?'75px !important': '75px !important'">
    <!-- <div class="btn-group btn-group-justified" data-toggle="buttons">
        <label [ngClass]="{
      'btn btn-default': true,
      active: activity==currentActivity
    }" *ngFor="let activity of activities" (click)="setActivity(activity)">
      <input type="radio" name="activity"/>
      {{activity.title}} ({{activity.data.length}})
    </label>

    </div> -->

    <!-- notification content -->
    <div style="color: #959595;
    text-align: center;
    margin-top: 10px;" >{{'common_no_data_available' | i18n}}</div>
    <div class="ajax-notifications custom-scroll" *ngIf="activityType=='message'">
        <ul class="notification-body" *ngIf="currentActivity">
            <ng-template ngFor let-item [ngForOf]="currentActivity.data">

                <li activitiesMessage *ngIf="item.type == 'message'" [item]="item"></li>
                 <li activitiesNotification *ngIf="item.type == 'notification'" [item]="item"></li>
                <!--<li activitiesTask *ngIf="item.type == 'task'" [item]="item" [lastUpdate]="lastUpdate"></li> -->

            </ng-template>

        </ul>
       
    </div>
    <!-- end notification content -->

    <!-- footer: refresh area -->
    <!-- <span class="messageFooter" *ngIf="activityType=='message'"><a>View all messages</a></span> -->
    <!-- <span> Last updated on: {{lastUpdate | moment:"h:mm:ss a"}}
    <button type="button" (click)="update()"
            class="btn btn-xs btn-default pull-right">
        <i class="fa fa-refresh" *ngIf="!loading"></i>
        <i class="fa fa-refresh fa-spin" *ngIf="loading"></i>
        <span *ngIf="loading">loading...</span>
    </button>
    </span> -->
    <!-- end footer -->
</div>