import { Component, OnDestroy, ChangeDetectorRef, AfterContentInit } from '@angular/core';
import { AppService } from './app.service';
import { Router, ActivatedRoute, Params,NavigationStart } from '@angular/router';
import { CommonService } from './shared/common/common.service';
import { I18nService } from './shared/i18n';
import { Subscription } from 'rxjs';
import * as crypto from 'crypto-browserify'
import { environment } from '@env/environment';
declare var Buffer: any;
declare var $: any;

@Component({
  selector: 'custom-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  breadcrumpsArray: any = [];
  searchKey='';
  routeParam;
  searchStatus = localStorage.getItem("searchStatus");
  showBreadcrumbs: boolean = true;
  loadedDarkOptions;
  loadedDarkprofile;
  lanId: string;
  langChangeSubscription: Subscription;
  langaugeChanged: boolean = false;
  fullScreenSelected: boolean = true;
  activitySelected: boolean = false;
  notificationSelected: boolean = false;
  encryptionKey: string;
  isLoggedIn: boolean = false;
  module: string;

  constructor(private route: ActivatedRoute, public appService: AppService, public router: Router, private cd: ChangeDetectorRef, private commonService: CommonService, private i18nService: I18nService) {
    this.appService.setLocalStorageFunctions();
    
    this.encryptionKey = environment.encryptionKey;
    if (window.location.pathname.indexOf('search') === 1) {
      this.showBreadcrumbs = false;
    }
    this.langChangeSubscription = this.i18nService.langChangeEvent.subscribe(langObj =>
      setTimeout(() => {
        this.onLangChange(langObj);
      }, 500));
    var self = this;
    $('body').off('keypress', '#search').on('keypress', '#search', function (event) {
      // $("#search").keypress(function(event) {
      if (event.which == 13) {
        self.goToSearch()
      }
    });
  }
  onLangChange(langObj: any) {
    langObj.isHandled = true;
    this.loadedDarkOptions = false;
    this.loadedDarkprofile = false;
    // this.loadedLightprofile = false;
    if (this.langaugeChanged) {
      this.lanId = this.commonService.getCurrentLanguageCode();
      // setTimeout(() => {
      if (this.isLoggedIn) {
        this.loadProfile();
        this.loadOptions();
      }
      else {
        $(".ajax-dropdown").addClass("ajax-dropdownNoRight");
      }

      // },1000);
    }

    this.langaugeChanged = true;
  }
  ngOnDestroy() {
    localStorage.removeEncryptedItem('section');
    localStorage.removeEncryptedItem('category');
  }

  ngAfterViewChecked() {
    this.appService.module = this.router.url.split("/")[1];
    this.cd.detectChanges();
    // if(this.router.url.indexOf('search')!=-1){
      
    //   this.searchKey=this.router.url.split('/')[3];
      
    // }
  }
  ngOnInit(): void {
    this.isLoggedIn = this.checkCookie();
    if(this.isLoggedIn) {
      let cookieExist = JSON.parse(this.commonService.decrypt(decodeURIComponent(this.getCookie(environment.checkCookie))));
        // let cookieExist = {"cookieCreatedTime":"2022-03-14T06:02:01.000Z","userMail":"support@io-market.com","partyID":"42","userID":17,"accId":17,"netcomId":"41420-0000411656-84"}
        if (cookieExist) {
          localStorage.setEncryptedItem('userMail',cookieExist['userMail']);
          localStorage.setEncryptedItem('partyID',cookieExist['partyID']);
          localStorage.setEncryptedItem('userID',cookieExist['userID']);
          localStorage.setEncryptedItem('accId',cookieExist['accId']);
        } 
    }
    this.onSelectTheme('');
    this.searchStatus = 'false'
    this.route.params.subscribe((params: Params) => {
      this.routeParam = params.searchkey;
    });
    this.searchKey = localStorage.getItem("searchItem");
    if (this.isLoggedIn) {
      this.loadOptions();
      this.loadProfile();
    }
    else {
      $(".ajax-dropdown").addClass("ajax-dropdownNoRight");
    }

   localStorage.clear();
  }
  goToSearch() {
    if (this.searchKey == '') {
      this.router.navigate(['/'+this.appService.module])
    }
    else {
      this.router.navigate([this.appService.module+'/search', this.searchKey])
      setTimeout(() => {
        if (window.location.pathname.indexOf('search') === 1) {
          this.showBreadcrumbs = false;
        }
      }, 100);
    }
    localStorage.setItem("searchItem", this.searchKey)
  }

  onSelectTheme(e) {
    let $body = document.getElementsByTagName('body');
    $body[0].classList.contains('smart-style-2') ? $body[0].classList.remove('smart-style-2') : $body[0].classList.remove('smart-style-1');
    if (e != '' && e.currentTarget.checked == true) {
      $body[0].classList.add('smart-style-2');
    }
    else {
      $body[0].classList.add('smart-style-1');
    }
    localStorage.setItem('sm-skin', 'smart-style-2');
  }


  loadOptions(): void {

    if (this.loadedDarkOptions) return;
    const script = document.createElement('script');
    // script.src = 'assets/webComponent/options.js';
    script.src = "https://wc.io-procurement.com/subscribedApps/main.js";
    document.body.appendChild(script);
    this.loadedDarkOptions = true;
    const content = document.getElementById('webComponetsOptions');
    this.lanId = this.commonService.getCurrentLanguageCode();
    let optiondata: any = { isWork: true, lang: this.lanId };
    optiondata = JSON.stringify(optiondata);
    content.innerHTML = `<custom-button optiondata='${optiondata}'></custom-button>`;
  }
  loadProfile(): void {
    if (this.loadedDarkprofile) return;
    const script = document.createElement('script');
    script.src = 'https://wc.io-procurement.com/profile/main.js';
    document.body.appendChild(script);
    this.loadedDarkprofile = true;
    const content = document.getElementById('webComponetsProfile');
    this.lanId = this.commonService.getCurrentLanguageCode();
    // this.userService.userData().subscribe(res=>{
    //   let user = res['data']
    //   localStorage.setEncryptedItem("userData",JSON.stringify(user))
    let profileData: any = { lang: this.lanId };
    profileData = JSON.stringify(profileData);
    content.innerHTML = `<profile-button profiledata='${profileData}'></profile-button>`;
    //   this.company = user[0].companyDetails[0].legalname;
    // })
  }
  redirectTologin() {
    if (this.isLoggedIn)
      this.router.navigate(['/ticket'])
    else
      window.location.href = environment.LOGIN_URL + encodeURIComponent(this.encrypt(window.location.origin + "/#/ticket"))
  }
  selectFullScreen(data) {
    this.fullScreenSelected = data;
  }
  encrypt(data) {

    var binaryEncryptionKey = new Buffer(this.encryptionKey, "base64");
    var binaryIV = new Buffer(0);

    var cipher = crypto.createCipheriv("AES-128-ECB", binaryEncryptionKey, binaryIV);
    var encryptedInput = (
      cipher.update(data, "utf8", "base64") +
      cipher.final("base64")
    );
    return encryptedInput;

  }
  checkCookie() {
    let cookieExist = this.getCookie(environment.checkCookie);
    let cookieValid = false;
    if (cookieExist != "") {
      let cookie = this.commonService.decrypt(decodeURIComponent(cookieExist));
      let expireTime = JSON.parse(cookie).cookieCreatedTime;
      cookieValid = new Date() < new Date(expireTime);
    }
    if (cookieExist != "" && cookieValid) {
      return true;
    }
    return false;
  }

  getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  selectActivity(data) {
    // this.activitySelected = data;
    if (data.type == 'notification') {
      this.notificationSelected = data.value;
    }
    else
      this.activitySelected = data.value;
  }

  goTo(route) { 
      this.router.navigate([this.appService.module+route]) 
  }

}
