<span class={{item.status}}>
  <a class="msg">
    <img src={{item.image}} alt="" class="air air-top-left margin-top-5 setborder" width="50" height="50" />
    <div class="activeStat"></div>
    <div class="row">
      <div class="col-sm">
        <span class="from pl-2">{{item.title}}</span>
</div>
<div class="col-sm" *ngIf="item.status!='read'">
    <b class="badge bg-color-blue msgNum pull-right">10</b>
</div>
</div>
<!-- <span class="subject pl-2">{{item.subject}}</span> -->
<span class=" activityBody msg-body pl-2">{{item.message}}</span>
<span class=" activityTime pl-2">{{item.time}}</span>
</a>
</span>
