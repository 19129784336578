import { Component, OnInit, ElementRef, OnDestroy, Renderer2, Output, EventEmitter, Input } from '@angular/core';
import {ActivitiesService} from "./activities.service";

declare var $: any;

@Component({
  selector: 'sa-activities',
  templateUrl: './activities.component.html',
  styleUrls:['./activities.component.css'],
  providers: [ActivitiesService],
})
export class ActivitiesComponent implements OnInit, OnDestroy {
  @Output() onActivitySelect = new EventEmitter<any>();
  @Input() activityType: any;
  count:number;
  lastUpdate:any;
  active:boolean;
  activities:any;
  currentActivity: any;
  loading: boolean;

  constructor(
    private el:ElementRef,
    private renderer: Renderer2,
    private activitiesService:ActivitiesService,
    ) {
    this.active = false;
    this.loading = false;
    this.activities = [];
    this.count = 0;
    this.lastUpdate = new Date();
  }

  ngOnInit() {
    let data=this.activitiesService.getActivities();
      this.activities = data;
      // this.count = data.reduce((sum, it)=> sum + it.data.length, 0);
      if(this.activityType=='message')
      this.currentActivity = data[0];
      if(this.activityType=='notification')
        this.currentActivity = data[1];

  }

  setActivity(activity){
    this.currentActivity = activity;
  }

  private documentSub: any;
  onToggle() {
    let dropdown = $('.ajax-dropdown', this.el.nativeElement);
    this.active = !this.active;
    let obj = {value:this.active, type:this.activityType}
    this.onActivitySelect.emit(obj);
    if (this.active) {
      dropdown.fadeIn()
      this.documentSub = this.renderer.listen('document', 'mouseup', (event) => {
          if (!this.el.nativeElement.contains(event.target)) {     
              dropdown.fadeOut();
              this.active = false;
              this.documentUnsub();
              let obj = {value:this.active, type:this.activityType}
              this.onActivitySelect.emit(obj);
          }
       });
    } else {
      dropdown.fadeOut();
      this.documentUnsub();
    }
  }



  update(){
    this.loading= true;
    setTimeout(()=>{
      this.lastUpdate = new Date()
      this.loading = false
    }, 1000)
  }


  ngOnDestroy(){    
    this.documentUnsub()
  }

  documentUnsub(){
    this.documentSub && this.documentSub();
    this.documentSub = null
  }

}
