import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {languages} from '../languages.model'
import {I18nService} from "../i18n.service";
import { Subscription } from 'rxjs';
import { EmitService } from 'src/app/shared/service/emit.service';

declare var $: any;
@Component({
  selector: 'sa-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls:['./language-selector.component.css']
})
export class LanguageSelectorComponent implements OnInit {

  public languages: Array<any>;
  public currentLanguage: any;
  reload:boolean=true;
  loadedinConstructor=false;
  langSubscription: Subscription;
  @ViewChild('languageList') languageList: ElementRef;
  getStatus = false;

  constructor(private i18n: I18nService,private emitService:EmitService) {
    this.langSubscription=this.emitService.langLoginChange.subscribe(data=>{
      this.loadedinConstructor=true;
      this.currentLanguage = this.i18n.currentLanguage;
      this.currentLanguage.key=data;
      this.setLanguage(this.i18n.currentLanguage);
      this.reload=true;
    });
    $(document).on('click',(event)=>{ 
      var x = this.languageList.nativeElement;
      if (x.style.display === "block" && !this.getStatus){
        x.style.display = "none"; 
      }
      this.getStatus = false;
    });
  }

  ngOnInit() {
    if(!this.loadedinConstructor){
      let currentLang = localStorage.getEncryptedItem("current_lang") ? JSON.parse(localStorage.getEncryptedItem("current_lang")) : {};
      let language = Object.keys(currentLang).length === 0 && currentLang.constructor === Object ?  this.i18n.currentLanguage : currentLang;
      this.setLanguage(language);
    }
  }

  ngOnDestroy() {
    this.langSubscription.unsubscribe();
  }
  public setLanguage(language){
    this.reload=false;
    this.languages =  this.i18n.setLanguageData(language.key);
    this.currentLanguage = language;
    this.i18n.setLanguage(language);
    this.reload=true;
  }
  showList(e) {
    $(".language-list").toggle();
    this.getStatus = true;
  }
}
